/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'money': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M8 16a1 1 0 00-2 0 5.006 5.006 0 005 5v1a1 1 0 002 0v-1a5 5 0 000-10V5a3 3 0 013 3 1 1 0 002 0 5.006 5.006 0 00-5-5V2a1 1 0 00-2 0v1a5 5 0 000 10v6a3 3 0 01-3-3zm5-3a3 3 0 010 6zM8 8a3 3 0 013-3v6a3 3 0 01-3-3z"/>'
  }
})
